@use "~@/common/scss/variables/theme";

div[class*="MuiToolbar-root"] {
  background-color: theme.$darkblue;
}

div[class*="MuiPickersBasePicker-container"] {
  position: relative;
}

div[class*="MuiPickersBasePicker-pickerView"] {
  padding: 0;
  min-height: 300px;
  justify-content: flex-start;
}

button[class*="MuiPickersDay-daySelected"] {
  background-color: theme.$darkblue !important;
  color: theme.$white !important;
}

div[class*="MuiDialogActions-root"] {
  button {
    color: theme.$darkblue;
  }
}

button[class*="MuiPickersDay-current"],
div[class*="MuiPickersYear-yearSelected"],
div[class*="MuiPickersMonth-monthSelected"] {
  color: theme.$darkblue;
}

div[class*="MuiPickersYear-root"],
div[class*="MuiPickersMonth-root"] {
  &:focus {
    color: theme.$darkblue;
  }
}

div[class*="MuiPickersStaticWrapper-staticWrapperRoot"] {
  width: fit-content !important;
}

div[class*="MuiDialogActions-root"] {
  border-top: 1px solid theme.$lightgray !important;
}

.is-multi-or-block {
  div[class*="MuiPickersCalendar-week"] {
    margin: 4px 0 !important;
  }

  button[class*="MuiPickersToolbarButton-toolbarBtn"]:nth-child(2) {
    display: none !important;
  }
}

.toolbar {
  background-color: theme.$darkblue;
  color: theme.$white;
  padding: 8px;

  .toolbar-button {
    color: rgba(255, 255, 255, 0.54);
    text-transform: capitalize;
    font-weight: 400;
    line-height: 1.75;
    font-size: 1rem;
    min-width: 0;
  }

  .invisible-month-button {
    position: absolute;
    background-color: transparent;
    z-index: 1;
    height: 36px;
  }

  .portrait {
    top: 63px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
  }

  .landscape {
    top: 11px;
    left: calc(31px + 50%);
    transform: translateX(-50%);
    width: 185px;
  }
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.3s;

  .date-picker {
    input[class*="MuiInputBase-input"] {
      font-family: theme.$font;
      height: initial;
      border: 1px solid theme.$light-border;
      color: theme.$black;
      background-color: theme.$white;
      font-size: 14px;
      line-height: 1.6;
      border-radius: 2px;
      padding: 7px 11px;
      transition: all 0.3s;

      &::placeholder {
        color: theme.$black;
        opacity: 0.4;
      }

      &:placeholder-shown {
        text-overflow: ellipsis;
      }

      &:hover {
        border-color: theme.$lightblue;
      }

      &:focus {
        border-color: theme.$lightblue;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        outline: 0;
      }
    }

    fieldset[class*="MuiOutlinedInput-notchedOutline"] {
      display: none;
    }
  }

  .error {
    input[class*="MuiInputBase-input"] {
      border-color: theme.$dark-error;

      &:hover {
        border-color: theme.$dark-error;
      }

      &:focus {
        border-color: theme.$dark-error;
        box-shadow: 0 0 0 2px rgba(226, 104, 104, 0.2);
      }
    }
  }

  .clear {
    position: absolute;
    right: 10px;
    top: 30px;
    font-size: 16px;
    opacity: 0.2;

    &:hover {
      opacity: 0.65;
      cursor: pointer;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;

  input[class*="MuiInputBase-input"] {
    border-color: theme.$light-border !important;
  }
}
