@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";
@import "../styles";

$headertype_color: rgba(124, 198, 255, 1);
$headertype_hover_color: rgba(54, 167, 255, 1);

.ListContainer {
  margin-top: 40px;
  height: calc(100vh - 40px);

  &.activity {
    width: calc(100% - 400px);
  }

  .ListTable {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .TableActions {
      padding: 10px;

      .Search {
        @include span-columns(9);
      }

      .count {
        @include span-columns(2.5);
        text-transform: uppercase;
        padding: 8px 20px;
        color: $viewer_black;
      }

      .controls {
        @include span-columns(2.5);
        font-size: 18px;

        .pagination {
          @include span-columns(12);
          padding: 3px;
          font-size: 10px;
          text-align: right;

          .current-page {
            display: inline-block;
            padding: 8px 5px;
            width: 70px;
            text-align: center;
          }

          i {
            padding: 0 5px;
            font-size: 14px;

            &:hover {
              color: $gray1;
              cursor: pointer;
            }
          }
        }

        & .columns {
          position: absolute;
          right: 5px;
          display: none;

          > i {
            padding: 7px 2px;
            font-size: 22px;

            &:hover {
              color: $gray1;
              cursor: pointer;
            }
          }

          .column-selector {
            position: relative;

            .ListTableOptions {
              position: absolute;
              right: -2px;
              top: -4px;
              width: 200px;
              background-color: rgba(255, 255, 255, 1);
              @include flydown-shadow;
              display: none;

              .list-item {
                padding: 5px 10px;
                font-size: 12px;
                text-transform: uppercase;
                color: $viewer_black;

                &:hover {
                  cursor: pointer;
                  background-color: rgba(0, 0, 0, 0.1);
                }

                &.inactive {
                  color: $lightgray;
                }

                & i {
                  padding-right: 10px;
                }
              }

              &:before {
                display: none;
                content: "";
                @include triangle(8px, #fff, up);
                position: absolute;
                top: -4px;
                right: 4px;
              }
            }

            &.active {
              .TableContainerColumnSelector {
                display: block;
              }
            }
          }
        }
      }
    }

    .TableContainer {
      flex: 1;
      overflow: auto;
      padding-bottom: 240px;

      table {
        width: 100%;
        border-collapse: collapse;

        thead {
          th {
            z-index: 5;
            background-color: $headertype_color;
            position: sticky !important;
            top: 0;

            &:hover {
              background-color: $headertype_hover_color;
            }
          }
        }

        tbody {
          background-size: 100%;
          background: repeating-linear-gradient(-55deg, #ffffff, #ffffff 2%, #f5f5f5 2%, #f5f5f5 4%);
        }

        .Row {
          width: 100%;
          background-color: $grid_lightrow_color;
          border-bottom: none;

          &.Spacer {
            color: $darkblue;
            text-transform: uppercase;
            font-size: 13px;
            background-color: $white;

            & .Cell {
              padding: 15px 0px 5px;
              border: 1px solid $white;

              & .text-container {
                text-align: left;
                transition: (margin-left 0.2s ease-out 0s);
              }

              &:hover {
                background-color: $white !important;
                cursor: default;
              }
            }
          }

          .Cell {
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            border-right: $grid_normalborder;
            min-width: 100px;

            .text-container {
              padding: 5px 20px;
            }

            .text {
              i.fa {
                padding-right: 5px;

                &.fa-arrow-right {
                  padding-left: 5px;
                }
              }
            }

            &:last-child {
              border-right: none;
            }

            &.col-small {
              min-width: 150px;
            }

            &.col-medium {
              min-width: 200px;
            }

            &.col-large {
              min-width: 300px;
            }

            &.wordwrap {
              white-space: normal;
            }

            & span.drag-left {
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 10px;
              display: none;

              &:hover {
                cursor: col-resize;
              }
            }

            & span.drag-right {
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 10px;
              display: none;

              &:hover {
                cursor: col-resize;
              }
            }

            & p {
              margin: 0;
              padding: 0;
            }

            a {
              color: $lightblue;

              &:hover {
                color: $darkdarkdarkblue;
              }
            }
          }

          &.even {
            background-color: $grid_darkrow_color;
            border-bottom: none;

            &.on-now {
              background-color: #ddf4d9;
            }
          }

          &.on-now {
            background-color: #e3fadf;
          }

          &:hover {
            background-color: $lightlightgray !important;
            cursor: pointer;

            .Cell {
              color: inherit;
            }
          }

          &.request {
            background-color: rgba(0, 0, 0, 0.035);

            &.granted {
              background-color: rgba(185, 243, 174, 0.4);
            }

            &.denied {
              background-color: rgba(255, 0, 0, 0.25);
            }

            &.suspended {
              background-color: rgba(62, 171, 255, 0.1);
            }

            &.pending {
              background-color: rgba(62, 171, 255, 0.1);
            }

            &.deleted {
              background-color: rgba(255, 149, 45, 0.25);
            }

            &:hover {
              background-color: rgba(0, 0, 0, 0.15) !important;
            }
          }

          &.header {
            width: 100%;
            border-bottom: none;
            border-top: none;

            .Cell {
              color: $white;
              text-transform: uppercase;
              border-right: 1px solid $lightblue;
              font-weight: normal;
              text-align: center;

              &:last-child {
                border-right: none;
              }

              &:hover {
                cursor: pointer;
                //background-color: $headertype_active_color;
              }

              &.sortAnchor {
                cursor: pointer;
                //background-color: $headertype_hover_color;
              }

              i {
                padding-left: 5px;
              }
            }

            &:hover {
              //background-color: $headertype_color !important;
              color: $white !important;
              cursor: pointer;
            }
          }
        }

        .empty-row {
          font-size: 12px;
          padding: 10px;
          text-align: center;
          display: none;
        }
      }
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@import "./responsive";
