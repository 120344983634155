.print-container {
  visibility: hidden;
  position: absolute;
  height: auto;

  &--outside {
    display: block;
  }
}

.print-container-content {
  &--page-spacing {
    .CalendarContainer {
      .WeekContainer {
        page-break-before: always;
      }
    }

    .StandardContainer {
      .header-wrapper {
        page-break-before: always;
      }
    }
  }

  &--minimal {
    .CalendarContainer {
      .WeekContainer {
        page-break-before: avoid;
      }
    }

    .StandardContainer {
      .header-wrapper {
        page-break-before: avoid;
      }
    }

    .ColumnContainer {
      .header-wrapper {
        page-break-inside: avoid;
      }
    }
  }
}

@media print {
  .ListContainer {
    .TableActions {
      display: none;
    }
    table {
      table-layout: fixed;
      width: 100vw;
      .Cell {
        padding: 0 !important;
        min-width: unset;

        .text-container {
          padding: 0.2em !important;
          white-space: pre-wrap;
          word-break: break-word;
          min-width: unset;
          font-size: clamp(6px, 1vmin, 18px);
        }
      }
    }
  }
  .CalendarContainer {
    .WeekContainer {
      page-break-inside: avoid;

      .DateBox {
        .header,
        .assignment,
        .time {
          font-size: 7pt !important;
        }
        .slots {
          padding-top: 0;
        }
      }
    }
  }

  .StandardContainer {
    .header-wrapper,
    .data-groups,
    .data-group,
    .data-rows {
      page-break-inside: avoid;
    }

    .group-title {
      font-size: 7pt !important;
      padding: 2px 5px !important;
    }

    .left-col-head,
    .date,
    .header,
    .leftCol,
    .times,
    .StandardTally,
    .StandardSlot {
      font-size: 7pt !important;
      min-height: unset !important;
      line-height: unset !important;
    }
  }

  .ColumnContainer {
    .header-wrapper,
    .row-wrapper {
      page-break-inside: avoid;
    }
    .group-banner {
      @media print {
        font-size: unset !important;
      }

      padding: 2px 5px !important;
    }
    .header {
      &__left-column {
        &--condensed {
          height: unset !important;
        }
      }
    }
    .header,
    .cell,
    .fixed-cell,
    .slot,
    .times {
      @media print {
        font-size: unset !important;
      }

      line-height: unset !important;
    }
  }

  .CalendarContainer,
  .StandardContainer,
  .ColumnContainer,
  .ListContainer {
    height: initial !important;
    margin: 0 !important;

    .spacer {
      display: none !important;
    }
  }
}

@page {
  margin: 12mm 4mm;
}
