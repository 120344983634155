/* search styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.Search {
  border: 1px solid $lightlightgray;
  margin-bottom: 5px;

  .search-icon {
    display: inline-block;
    padding: 4px 10px;
    vertical-align: middle;

    i {
      font-size: 20px;
    }
  }

  .search-tags {
    display: inline-block;
    padding: 2px 2px;
    max-width: 500px;

    .search-tag {
      display: inline-block;
      padding: 5px;
      margin-right: 2px;
      background-color: $lightlightlightgray;
      border: 1px solid $lightlightgray;

      & i {
        font-size: 12px;
        margin-right: 5px;

        &:hover {
          color: $gray1;
          cursor: pointer;
        }
      }
    }
  }

  .search-field {
    display: inline-block;

    & input {
      font-size: 12px;
      padding: 9px;
      border: 0;
      width: 400px;

      &:focus {
        outline: none;
      }
    }
  }
}
