// directory variables
$img_path: "/images/";

$brand-lightblue: #3bace0;
$brand-darkblue: #073b89;

// base colors
$white: #ffffff;
$black: #000000;
$gray1: #111111;
$gray3: #333333;
$viewer_black: #080e33;
$gray6: #270505;
$gray7: #e5e5e5;
$lightgray: #ababab;
$lightlightgray: #dcdcdc;
$lightlightlightgray: #f0f0f0;
$extremelightgray: #f9f9f9;
$lightlightlightorange: #ffe599;
$lightlightorange: #ffd866;
$orange: #ff8000;
$lightorange: #ff952d;
$brownorange: #997200;
$darkorange: #f46903;
$darkdarkorange: #ca5602;
$darkdarkdarkorange: #ae4a02;
$lightlightlightblue: #e9f4fc;
$lightblue: #2694e8;
$mediumblue: #498bc0;
$darkblue: #296ba0;
$darkdarkblue: #1f6196;
$darkdarkdarkblue: #15578c;
$lightgreen: #1bb900;
$lightlightlightgreen: #e5ffe0;
$green: #49b800;
$darkgreen: #358500;
$darkdarkgreen: #2b6c00;
$darkdarkdarkgreen: #215300;
$green2: #5bbf19;
$slate: #616d7e;
$postit: #feff84;
$lightpurple: #9079d3;
$purple: #663399;
$darkpurple: #553399;
$darkdarkpurple: #4d3399;
$darkdarkdarkpurple: #302060;
$red: #ff3333;
$lightred: #ff5b5b;
$lightlightlightred: #ffe4e4;
$darkred: #e52d2d;
$darkdarkred: #b22323;
$darkdarkdarkred: #7f1919;

// text thingz
$bodyfontfamily: "Open Sans", Lucida Grande, Lucida Sans, Arial, sans-serif;
$bodytextcolor: $viewer_black;
$bodytextsize: 12px;
$smallheadersize: 14px;
$medheadersize: 16px;
$largeheadersize: 18px;
$largerheadersize: 20px;

$img_logotile: url("../../../_lib/ui/images/logo_tile-small.png");
