@use "sass:map";
@use "~@/common/scss/variables/theme";

.root {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1200;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: theme.$backdrop;

  .shell {
    max-height: 90%;
    z-index: 1000;
    margin: 32px;
    background-color: theme.$white;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: theme.$border-radius;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 0 auto;
      padding: 8px 18px 8px 28px;
      cursor: move;

      .title {
        color: theme.$blue;
        font-size: 22px;
      }

      .close-icon {
        color: theme.$gray;
        margin-right: -6px;

        span {
          font-size: 20px;
        }
      }
    }

    .content {
      flex: 1 1 auto;
      overflow: auto;
    }

    .footer {
      flex: 0 0 auto;
      padding: 16px 18px;
      border-top: 1px solid theme.$light-border;

      .checkbox-action {
        margin-top: 2px;
        margin-bottom: 14px;
      }
    }

    .no-drag {
      cursor: unset;
    }
  }

  .full-width {
    width: calc(100% - 64px);
  }

  .no-max-width {
    max-width: calc(100% - 64px);
  }

  .xs {
    max-width: 450px;
  }

  .sm {
    max-width: map.get(theme.$breakpoints, "sm");
  }

  .md {
    max-width: map.get(theme.$breakpoints, "md");
  }

  .lg {
    max-width: map.get(theme.$breakpoints, "lg");
  }

  .xl {
    max-width: map.get(theme.$breakpoints, "xl");
  }
}

.no-backdrop {
  background-color: unset;
}
