/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";
@import "../_styles";

// remove the up/down arrows
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none!important;
  margin: 0;
}

.timeInputContainer {
  width: 24px;
  height: 24px;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  vertical-align: middle;
  .timeInput {
    width: 24px;
    height: 24px;
    text-align: center;
    font-size: 12px;
    border-color: $lightlightgray;
  }
}

.dashSymbol {
  padding: 7px;
}

.fa-chevron-up,
.fa-chevron-down {
  font-size: 21px;
  padding: 4px;
  cursor: "pointer";
  &.blackArrow {
    color: $black;
  }
  &.lightblueArrow {
    color: $lightblue;
  }
}

.moveDialog {
  &-footerContainer {
    box-shadow: 0 -3px 8px 0 rgba(0, 0, 0, 0.25);
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    button {
      height: 66px;
      border-radius: unset;
    }

    .footerContainer-button__submit,
    .footerContainer-button__next {
      position: absolute;
      right: 0;
    }

    .footerContainer-button__back,
    .footerContainer-button__next {
      background-color: $lightlightlightgray;

      &:hover {
        background-color: $gray7;
      }
    }

    .footerContainer-button__submit {
      color: white;
      background-color: $green2;

      &:hover {
        background-color: $green;
      }
    }
  }

  .MuiToolbar-root {
    height: 10px;

    .MuiTypography-h4 {
      padding-bottom: 5px;
    }

    .MuiTypography-root {
      font-size: 20px;
    }
  }

  .details {
    & .sentence-container {
      & .sentence {
        font-size: 12px;

        .value {
          color: $gray1;
          font-size: 12px;
        }

        i {
          font-size: 16px;
          color: $gray1;
        }
      }

      span.underline {
        text-decoration: underline;
      }

      span.semi-large {
        color: $gray1;
        font-size: 13px;
      }

      span.large {
        color: $gray1;
        font-size: 14px;
      }

      span.uppercase {
        text-transform: uppercase;
      }

      span.dark {
        color: $gray1;
      }

      span.gray {
        color: $lightgray;
      }
    }
  }

  .timeable {
    @include span-columns(14);
    margin-bottom: 2px;
    padding: 0 6px;

    .timeable-label {
      @include span-columns(4);
      text-transform: none;
      color: $darkblue;
      font-size: 13px;
      line-height: 32px;
    }

    .timeable-value {
      @include span-columns(10);
      @include pad(0);
      line-height: 32px;
      overflow: hidden;

      & .label {
        vertical-align: top;
        padding-left: 25px;
        font-size: 13px;
      }

      & .value {
        padding-right: 20px;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        /* Hide the browser's default radio button */
        & input {
          position: absolute;
          opacity: 0;
        }

        /* Create a custom radio button */
        & .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 18px;
          width: 18px;
          background-color: #eee;
          border-radius: 50%;
        }

        /* On mouse-over, add a grey background color */
        & input ~ .checkmark:hover {
          background-color: #ccc;
        }

        /* When the radio button is checked, add a blue background */
        & input:checked ~ .checkmark {
          background-color: #2196f3;
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        & .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the indicator (dot/circle) when checked */
        & input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the indicator (dot/circle) */
        & .checkmark:after {
          top: 6px;
          left: 6px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: white;
        }
      }
    }
  }

  .selectable-order {
    @include span-columns(14);
    margin-bottom: 2px;
    cursor: pointer;
    padding: 0 6px;

    &.disabled {
      cursor: default;

      .selectable-label {
        color: $darkblue;
      }

      .selectable-value {
        color: $lightgray;
        text-decoration: none;
      }

      &:hover {
        background-color: $white;
      }
    }

    .selectable-order-label {
      @include span-columns(4);
      text-transform: none;
      color: $darkblue;
      font-size: 13px;
      line-height: 31px;
    }

    .selectable-order-value {
      @include span-columns(10);
      font-size: 13px;
      padding: 5px 10px;
      background-color: $white;
      border: 1px solid $lightlightgray;

      &:hover {
        background-color: $lightlightlightgray;
        border: 1px solid $lightlightgray;
      }
    }

    .light {
      opacity: 0.5;
    }
  }

  .textable {
    @include span-columns(14);
    padding: 0 6px;
    margin-bottom: 5px;

    .textable-label {
      @include span-columns(4);
      text-transform: none;
      color: $darkblue;
      font-size: 13px;
      line-height: 31px;
    }

    .textable-value {
      @include span-columns(9);
      @include pad(5px 10px);
      font-size: 13px;
      background-color: $white;
      border: 1px solid $lightlightgray;
      height: 2.1em;

      &:hover {
        background-color: $lightlightlightgray;
        border: 1px solid $lightlightgray;
      }
    }

    i.fa.fa-ellipsis-v {
      @include span-columns(0.75);
      @include shift(0.25);
      @include pad(4px 5px 3px);
      font-size: 20px;
      color: $lightgray;

      &:hover {
        background-color: $lightgray;
        color: $white;
        cursor: pointer;
      }
    }
  }

  .selectable {
    @include span-columns(14);
    margin-bottom: 2px;
    cursor: pointer;
    padding: 0 6px;

    .selectable-label {
      @include span-columns(4);
      text-transform: none;
      color: $darkblue;
      font-size: 13px;
      line-height: 31px;
    }

    .selectable-value {
      @include span-columns(10);
      font-size: 13px;
      padding: 5px 10px;
      background-color: $white;
      border: 1px solid $lightlightgray;

      &:hover {
        background-color: $lightlightlightgray;
        border: 1px solid $lightlightgray;
      }
    }

    &.two-line {
      margin-bottom: 5px;
      background-color: $white;
      padding: 5px 6px;

      & .selectable-label {
        @include span-columns(14);
        color: $darkblue;
        font-size: 13px;
        line-height: 22px;
      }

      & .selectable-value {
        @include span-columns(14);
        background-color: inherit;
        border: none;
        padding: 0;
        font-size: 16px;
        color: $gray1;
        text-decoration: underline;

        &:hover {
          background-color: transparent;
          border: none;
        }
      }

      &:hover {
        background-color: $lightlightlightgray;
      }
    }

    &.disabled {
      cursor: default;

      .selectable-label {
        color: $darkblue;
      }

      .selectable-value {
        color: $lightgray;
        text-decoration: none;
      }

      &:hover {
        background-color: $white;
      }
    }
  }

  .checkable {
    @include span-columns(14);
    @include pad(0 6px);
    margin-top: 10px;
    margin-bottom: 2px;

    .checkable-value {
      & .label {
        vertical-align: top;
        padding-left: 30px;
        font-size: 13px;
      }

      & .value {
        font-size: 14px;
        display: block;
        position: relative;
        line-height: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        /* Hide the browser's default checkbox */
        & input {
          position: absolute;
          opacity: 0;
        }

        /* Create a custom checkbox */
        & .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 18px;
          width: 18px;
          background-color: #eee;
        }

        /* On mouse-over, add a grey background color */
        & input ~ .checkmark:hover {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        & input:checked ~ .checkmark {
          background-color: #2196f3;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        & .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        & input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        & .checkmark:after {
          left: 7px;
          top: 3px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }

  .customizable {
    @include span-columns(14);
    padding: 0 6px;
    margin-bottom: 5px;

    .custom-label {
      @include span-columns(4);
      text-transform: none;
      color: $darkblue;
      font-size: 13px;
      line-height: 13px;
    }

    .custom-value {
      @include span-columns(10);
      @include pad(5px 10px);
      font-size: 13px;
      background-color: $white;
      border: 1px solid $lightlightgray;
      height: 2.1em;

      &:hover {
        background-color: $lightlightlightgray;
        border: 1px solid $lightlightgray;
      }
    }
  }
}

.submit {
  @include span-columns(14);
  @include pad(15px);
  text-align: center;

  i {
    padding: 10px;
    border-radius: 50%;
    background-color: $green2;
    color: $white;
    font-size: 32px;

    &:hover {
      background-color: $green;
      cursor: pointer;
    }

    &.disabled {
      background-color: $extremelightgray;
      border: 1px solid $lightlightlightgray;
      color: $lightgray;

      &:hover {
        background-color: $extremelightgray;
        border: 1px solid $lightlightlightgray;
        color: $lightgray;
        cursor: default;
      }
    }
  }
}

.DialogNote {
  @include dialog-shadow;

  /* cross-dialog items */
  & .close {
    position: absolute;
    top: 2px;
    right: 0;
    padding: 6px 10px 5px;
    z-index: 100;

    i {
      font-size: 24px;
    }

    &:hover {
      cursor: pointer;
      color: $gray1;
    }
  }

  @include animation(bounce 0.2s ease-out);
}

.DialogNote.NoteFull {
  width: 300px;
  height: auto;
  background-color: $lightlightorange;

  & .close {
    color: $brownorange;

    &:hover {
      color: $gray1;
    }
  }

  .content {
    @include span-columns(14);
    @include pad(5px 0 5px 0);

    .header {
      @include span-columns(12);
      margin-left: 5px;
      color: $brownorange;
      font-size: 14px;
      line-height: 23px;
      text-transform: uppercase;
      padding: 5px 0 5px 5px;
      position: relative;

      .tag {
        padding: 2px 5px;
        font-size: 14px;
        text-transform: uppercase;
        margin-right: 5px;
      }
    }
  }

  .editables {
    @include span-columns(14);
    @include pad(0 0);

    & .note-text-container {
      @include span-columns(14);
      @include pad(0 0);

      & textarea {
        @include pad(10px);
        border: none;
        background-color: $lightlightlightorange;
        width: 100%;
      }
    }
  }
}
