/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

@include media($mobile) {
  .TableActions {
    .Search {
      width: 200px !important;

      input {
        width: 150px !important;
      }
    }

    .count {
      display: none !important;
    }

    .controls {
      width: 150px !important;
      padding: 2px !important;
      text-align: center;
      float: right !important;
    }
  }
}
