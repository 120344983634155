/* Brand Colors */
$orange: #ff8000;
$darkblue: #0a4387;
$lightblue: #42b3e3;
$blue: #15578c;
$gray: #d0cfcd;
$darkgray: #808282;
$lightgray: #f2f2f2;
$black: #080e33;
$white: #ffffff;
$link: #2694e8;
$light-error: #f56969;
$dark-error: #e26868;
$light-border: #d9d9d9;
$dark-border: #d4d4d4;
$backdrop: rgba(0, 0, 0, 0.45);

/* Breakpoints */
$breakpoints: (
  "xs": 0,
  "sm": 600px,
  "md": 960px,
  "lg": 1280px,
  "xl": 1920px,
);

/* CSS Defaults */
$font: "Open Sans", Lucida Grande, Lucida Sans, Arial, sans-serif;
$border-radius: 4px;
